import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
	options: {
		customProperties: true
	},
	theme: {
		dark: true,
		themes: {
			dark: {
				primary: '#1CBB73',
				background: '#1A2332',
				dark: '#121722'
			},
		},
	},
});
