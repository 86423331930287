import Vue from 'vue';
import VueRouter from 'vue-router';
import VueCookies from 'vue-cookies';
import store from '@/store';

Vue.use(VueRouter);

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/',
			name: 'Home',
			component: () => import('./components/Welcome.vue'),
			meta: {
				disallowAuthed: true,
				requiresAuth: false
			}
		},
		{
			path: '/dashboard',
			name: 'Dashboard',
			component: () => import('./components/Dashboard/Dashboard.vue'),
			meta: {
				requiresAuth: true
			}
		},
		{
			path: '/profile',
			name: 'Profile',
			component: () => import('./components/Profile/Profile.vue'),
			meta: {
				requiresAuth: true
			}
		},
		{
			path: '/team',
			name: 'Team',
			component: () => import('./components/Team/Team.vue'),
			meta: {
				requiresAuth: true
			}
		},
		{
			path: '/rankings',
			name: 'Rankings',
			component: () => import('./components/Rankings/Rankings.vue'),
			meta: {
				requiresAuth: true
			}
		},
		{
			path: '/commands',
			name: 'Commands',
			component: () => import('./components/Chat_Commands/Commands.vue'),
			meta: {
				requiresAuth: true
			}
		},
		{
			path: '/help',
			name: 'Help',
			component: () => import('./components/Help/Help.vue'),
			meta: {
				requiresAuth: true
			}
		},
		{
			path: '/streamerpanel',
			name: 'Streamer Panel',
			component: () => import('./components/StreamerPanel/StreamerPanel.vue'),
			meta: {
				requiresAuth: true,
				requireSuperUser: true
			},
			children: [
				{
					name: 'Rewards',
					path: 'rewards',
					component: () => import('./components/StreamerPanel/Rewards/Rewards.vue')
				},
				{
					name: 'Users',
					path: 'users',
					component: () => import('./components/StreamerPanel/Users/Users.vue')
				},
				{
					name: 'Team',
					path: 'team',
					component: () => import('./components/StreamerPanel/Team/Team.vue')
				},
				{
					name: 'Commands',
					path: 'commands',
					component: () => import('./components/StreamerPanel/Commands/Commands.vue')
				},
				{
					name: 'Interval Messages',
					path: 'intervalmessages',
					component: () => import('./components/StreamerPanel/Intervals/Intervals.vue')
				},
				{
					name: 'Earn Rates',
					path: 'earnrates',
					component: () => import('./components/StreamerPanel/EarnRates/EarnRates.vue')
				},
				{
					name: 'Categories',
					path: 'categories',
					component: () => import('./components/StreamerPanel/Categories/Categories.vue')
				},
				{
					name: 'Stream Info',
					path: 'streaminfo',
					component: () => import('./components/StreamerPanel/StreamerInfo/Info.vue')
				},
			]
		},
		{
			path: '*',
			name: '404',
			redirect: '/',
		}
	]
});

router.beforeEach(function (to, from, next) {
	const session = VueCookies.get('guardian_key_session');
	document.title = `${to.name} - ${process.env.VUE_APP_NAME}`;

	if (to.matched.some(attr => attr.meta.requireSuperUser)) {
		if (store.state.isSuperUser) {
			next();
		}
		else {
			next({ path: '/dashboard' });
		}
	}
	else if (to.matched.some(record => record.meta.requiresAuth)) {
		if (session) {
			next();
		} 
		else {
			next({ path: '/' });
			store.state.isLoggedIn = false;
			store.state.isSuperUser = false;
			store.state.user = {};
			
			Vue.$cookies.remove('guardian_key_session');

			Vue.notify({
				group: 'main',
				type: 'error',
				title: 'Logged out',
				text: 'You are logged out.'
			});
		}
	}
	else if (to.matched.some(attr => attr.meta.disallowAuthed)) {
		if (session) {
			next({ path: '/dashboard' });
		} 
		else {
			next();
		}
	}
});

export default router;