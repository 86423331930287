import Vue from 'vue';
import router from './router';
import store from '@/store';

export default {
	methods: {
		sessionExpiredHandler: function (error) {
			store.state.isLoggedIn = false;
			store.state.isSuperUser = false;
			store.state.user = {};
			
			Vue.$cookies.remove('guardian_key_session');

			if (error.error === 'Forbidden') {
				Vue.notify({
					group: 'main',
					type: 'error',
					duration: 20000,
					max: 1,
					title: 'Banned!',
					text: error.message
				});
			}
			else {
				Vue.notify({
					group: 'main',
					type: 'error',
					title: 'Logged out',
					text: error.message
				});
			}
			router.push("/");
		},

		getParameterByName(name) {
			name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
			var regexS = "[\\?&]" + name + "=([^&#]*)";
			var regex = new RegExp(regexS);
			var results = regex.exec(window.location.href);
			if (results == null) return;
			else return decodeURIComponent(results[1].replace(/\+/g, " "));
		},
	}
};