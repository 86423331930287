import Vue from 'vue';
import Vuex from 'vuex';
import VueCookies from 'vue-cookies';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		homepageExpand: false,
		user: {},
		streamers: [],
		isLoggedIn: false,
		isSuperUser: false,
		error_types: [
			'AuthTokenError', 
			'Forbidden', 
			'TokenExpired', 
			'JsonWebTokenError', 
			'TokenExpiredError', 
			'UserError',
			'invalidAccessToken',
		],
		base_url: process.env.VUE_APP_ROOT_API,
		redirect_uri: process.env.VUE_APP_REDIRECT_URI,
		app_name: process.env.VUE_APP_NAME,
		alert: true,
		userLoading: false,
	},

	mutations: {
		SET_USER_INFO(state, user) {
			state.user = user;
		}
	},

	actions: {
		async fetchUserInfo({ commit }) {
			this.state.userLoading = true;
			try {
				const request = await fetch(`${this.state.base_url}/user`, {
					method: 'GET',
					headers: {
						"Authorization" : VueCookies.get('guardian_key_session'),
					}
				});

				const res = await request.json();

				if (request.ok) {
					if (res.user) commit('SET_USER_INFO', res.user);
					else throw res.error;
				}

				if (request.status == 500 || request.status == 401) {
					if (res.error && this.$store.state.error_types.includes(res.error)) {
						this.sessionExpiredHandler(res);
						return;
					}

					throw (res);
				}
			} 
			catch (error) {
				this.$notify({
					group: 'main',
					type: 'error',
					title: this.$store.state.app_name,
					text: error.message === 'Failed to fetch' ? 'A network error occured. Try again later.' : error.message
				});
			}
			finally {
				this.state.userLoading = false;
			}
		}
	}
});