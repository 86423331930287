<template>
	<v-app id="app">
		<notifications group="main" position="top center" width="400px" class="my-4 user-select-none"/>

		<v-navigation-drawer mini-variant expand-on-hover clipped app width="220" v-if="isLoggedIn">
			<v-list dense nav>
				<v-list-item color="primary" v-for="item in items" :key="item.title" :to="item.route">
					<v-list-item-icon>
						<v-icon>{{ item.icon }}</v-icon>
					</v-list-item-icon>

					<v-list-item-content>
						<v-list-item-title>{{ item.title }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-group v-if="groups.length > 1">
					<template v-slot:activator>
						<v-list-item-icon>
							<v-icon>mdi-message-text</v-icon>
						</v-list-item-icon>

						<v-list-item-content>
							<v-list-item-title>Social</v-list-item-title>
						</v-list-item-content>
					</template>

					<v-list-item v-for="(group, i) in groups" :key="i" :href="group.link" target="_blank">
						<v-list-item-icon class="ml-8">
							<font-awesome-icon v-if="group.icon.toUpperCase() === 'discord'.toUpperCase()" icon="fa-brands fa-discord" size="lg"/>
						</v-list-item-icon>

						<v-list-item-title right>{{ group.title }}</v-list-item-title>
					</v-list-item>
				</v-list-group>
			</v-list>

			<template v-slot:append>
				<v-list dense nav>
					<v-list-item color="primary">
						<v-list-item-icon>
							<v-icon>mdi-tag</v-icon>
						</v-list-item-icon>

						<v-list-item-content>
							<v-list-item-title>v{{ version }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</template>
		</v-navigation-drawer>

		<v-app-bar app flat tile clipped-left v-if="isLoggedIn">
			<v-img
				class="shrink"
				:src="require(`./assets/${$imagePath}/main.png`)"
				transition="fade-transition" 
				width="190"
			/>

			<v-spacer></v-spacer>

			<v-btn text class="mx-2" color="primary" v-if="$store.state.isSuperUser" to="/streamerpanel">
				<v-icon left>mdi-application-cog-outline</v-icon>
				{{ $store.state.role }} Panel
			</v-btn>

			<v-menu v-if="isLoggedIn" :offset-y="true" transition="slide-y-transition" bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn icon color="primary" v-bind="attrs" v-on="on">
						<v-icon>mdi-help-circle</v-icon>
					</v-btn>
				</template>
				<v-list dense>
					<v-list-item to="/help">
						<v-list-item-title>F.A.Q.</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>

			<v-menu v-if="isLoggedIn" :offset-y="true" transition="slide-y-transition" bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn :ripple="true" text color="primary" v-bind="attrs" v-on="on">
						<v-img class="profile_pic_bar" :src="$store.state.user.profilePic" width="35" transition="fade-transition"></v-img>
						{{ $store.state.user.nickname }}
						<v-icon right>mdi-chevron-down</v-icon>
					</v-btn>
				</template>
				<v-list dense>
					<v-list-item @click="Logout" :disabled="loadingLogoutBtn" >
						<v-list-item-title>Logout</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-app-bar>

		<v-main v-if="isLoggedIn">
			<router-view></router-view>
		</v-main>
		<v-main v-else>
			<Welcome :loadingLoginBtn="loadingLoginBtn"/>
		</v-main>

		<v-footer padless class="transparent mt-8">
			<v-col class="text-center" cols="12">
				{{ new Date().getFullYear() }} &copy; <strong>{{ this.$store.state.app_name }}</strong>
				<p class="caption ma-0">Handcrafted by <a href="https://github.com/z3r0Luck" target="_blank">z3r0Luck</a></p>
			</v-col>
		</v-footer>

	</v-app>
</template>

<script>
import Welcome from './components/Welcome';

export default {
	components: {
		Welcome,
	},
	
	data() {
		return {
			version: '',
			loadingLoginBtn: false,
			loadingLogoutBtn: false,
			items: [
				{
					title: 'Dashboard',
					icon: 'mdi-view-dashboard',
					route: '/dashboard'
				},
				{
					title: 'Profile',
					icon: 'mdi-account',
					route: '/profile'
				},
				{
					title: 'Team',
					icon: 'mdi-account-group',
					route: '/team'
				},
				{
					title: 'Rankings',
					icon: 'mdi-finance',
					route: '/rankings'
				},
				{
					title: 'Commands',
					icon: 'mdi-robot-excited',
					route: '/commands'
				},
			],
			groups: []
		}
	},

	computed: {
		isLoggedIn() {
			return this.$store.state.isLoggedIn;
		},
	},

	async created() {
		const session = this.$cookies.get('guardian_key_session');
		const code = this.getParameterByName('code');
		const scope = this.getParameterByName('scope');

		try {
			if (session) {
				this.loadingLoginBtn = true;

				const request = await fetch(`${this.$store.state.base_url}/auth`, {
					method: 'GET',
					headers: {
						"Authorization" : session,
					},
				});

				const res = await request.json();

				if (request.ok) {
					if (res.isLoggedIn && res.token) {
						await this.$store.dispatch('fetchUserInfo');

						this.$store.state.isLoggedIn = res.isLoggedIn;
						this.$store.state.isSuperUser = res.isSuperUser;
						this.$store.state.role = res.role;
						this.$store.state.role_id = res.role_id;

						this.$cookies.set('guardian_key_session', res.token);

						this.loadingLoginBtn = false;
					}
				}

				if (request.status == 500 || request.status == 401 || request.status == 403) {
					if (res.error && this.$store.state.error_types.includes(res.error)) {
						this.sessionExpiredHandler(res);
						return;
					}

					throw (res);
				}
				
				await this.Version();
			}
			else if (code && scope) {
				await this.Login(code, process.env.VUE_APP_REDIRECT_URI, 'twitch')
				await this.Version();
			}
		} 
		catch (error) {
			this.$notify({
				group: 'main',
				type: 'error',
				title: this.$store.state.app_name,
				text: error.message === 'Failed to fetch' ? 'A network error occured. Try again later.' : error.message
			});
		}
		finally {
			this.loadingLoginBtn = false;
		}
	},

	methods: {
		async Login(code, redirect_uri, platform) {
			try {
				this.loadingLoginBtn = true;
				
				const request = await fetch(`${this.$store.state.base_url}/auth/login`, {
					method: 'POST',
					headers: {
						"Content-type" : "application/json"
					},
					body: JSON.stringify({
						code: code,
						redirect_uri: redirect_uri,
						platform: platform
					}),
				});
				
				const res = await request.json();

				if (request.ok) {
					if (res.isLoggedIn && res.token) {
						this.$cookies.set('guardian_key_session', res.token);
						await this.$store.dispatch('fetchUserInfo');

						this.$store.state.isLoggedIn = res.isLoggedIn;
						this.$store.state.isSuperUser = res.isSuperUser;
						this.$store.state.role = res.role;
						this.$store.state.role_id = res.role_id;

						this.loadingLoginBtn = false;

						this.$router.push('/dashboard');
					}
					else {
						this.$notify({
							group: 'main',
							type: 'error',
							title: this.$store.state.app_name,
							text: `${res.message} Try logging in again.`
						});
						
						this.$router.replace('/');
					}
				}

				if (request.status == 500 || request.status == 401) {
					if (res.error && this.$store.state.error_types.includes(res.error)) {
						this.sessionExpiredHandler(res);
						this.$router.replace('/');
						return;
					}
					
					this.$router.replace('/');
					throw (res);
				}
			} 
			catch (error) {
				this.$notify({
					group: 'main',
					type: 'error',
					title: this.$store.state.app_name,
					text: error.message === 'Failed to fetch' ? 'A network error occured. Try again later.' : error.message
				});
			}
			finally {
				this.loadingLoginBtn = false;
			}
		},

		async Logout() {
			try {
				this.loadingLogoutBtn = true;

				const request = await fetch(`${this.$store.state.base_url}/auth/logout`, {
					method: 'POST',
					headers: {
						"Authorization" : this.$cookies.get('guardian_key_session'),
					},
				});
				
				const res = await request.json();

				if (request.ok) {
					if (res.isLoggedOut) {
						this.$store.state.isLoggedIn = false;
						this.$store.state.isSuperUser = false;
						this.$store.state.user = {};
						this.$cookies.remove('guardian_key_session');
						this.$router.push('/');
					}
				}

				
			}
			catch (error) {
				this.$notify({
					group: 'main',
					type: 'error',
					title: this.$store.state.app_name,
					text: 'Unexpected error. You have been logged out.'
				});

				this.sessionExpiredHandler('');
				this.loadingLoginBtn = false;
			}
			finally {
				this.loadingLogoutBtn = false;
			}
		},

		async Version() {
			try {
				const request = await fetch(`${this.$store.state.base_url}/v`, {
					method: 'GET',
					headers: {
						"Authorization" : this.$cookies.get('guardian_key_session'),
					},
				});
				
				const res = await request.json();

				if (request.ok) {
					if (res.v) this.version = res.v
				}

				if (request.status == 500 || request.status == 401) {
					if (res.error && this.$store.state.error_types.includes(res.error)) {
						this.sessionExpiredHandler(res);
						return;
					}

					throw (res);
				}
			}
			catch (error) {
				this.$notify({
					group: 'main',
					type: 'error',
					title: this.$store.state.app_name,
					text: error.message === 'Failed to fetch' ? 'A network error occured. Try again later.' : error.message
				});
				
				this.version = '-';
			}
		}
	},
};
</script>

<style>
#app {
    background-color: #151d2c;
}

.theme--dark.v-navigation-drawer {
	background-color: #192335 !important;
}

.theme--dark.v-app-bar.v-toolbar.v-sheet {
	background-color: #192335 !important;
}

.v-tabs-items {
	background-color: #192335 !important;
}

.v-sheet.v-card {
	background-color: #192335;
}

.theme--dark.v-data-table {
	background-color: #192335 !important;
}

.theme--dark.v-data-table.v-data-table--fixed-header thead th {
	background-color: #192335 !important;
}

div[class*="v-list"] {
	background-color: #192335 !important;
}

div[class*="v-chip"] {
	background: #192335 !important;
}

div[class^="v-skeleton"], div[class*="v-skeleton"] {
    background-color: #192335 !important;
}

.theme--dark.v-text-field--solo > .v-input__control > .v-input__slot {
	background-color: #1aac6a !important;
}

::selection {
	background-color: #1aac6a;
}

.profile_pic {
    margin: 10px;
    border: 2px solid #19CA6E;
    border-radius: 500px;
    -webkit-border-radius: 500px;
    -moz-border-radius: 500px;
}

.profile_pic_bar {
    margin: 10px;
    border: 1px solid #19CA6E;
    border-radius: 500px;
    -webkit-border-radius: 500px;
    -moz-border-radius: 500px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.mgn_header {
	background: #06070EA6;
	box-shadow: 0 0 0 0 rgba(25, 202, 111, 0.411);
	backdrop-filter: blur(18px);
	-webkit-backdrop-filter: blur(11.1px);
}

.mgn_header_panel {
	background: #28767c79;
	backdrop-filter: blur(35px);
}

.mgn_bg_logo {
	background-image: url('/icon.png');
	background-position: center;
	background-size: contain;
	background-blend-mode: soft-light;
}

.mgn_chip {
	background: #19CA6E !important;
	min-width: 145px;
}

.mgn_chip_1 {
	background: #19CA6E !important;
	width: 100%;
}

.mgn_card {
	transition: 0.18s;
}

.mgn_card:hover {
	transform: scale(1.03);
}
</style>