import Vue from 'vue';
import router from './router';
import store from '@/store';
import vuetify from '@/plugins/vuetify';
import VueCookies from 'vue-cookies';
import Notifications from 'vue-notification';
import Vuelidate from 'vuelidate';
import App from '@/App.vue';
import helpers from './mixins';
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* import specific icons */
import { faDiscord, faTiktok } from '@fortawesome/free-brands-svg-icons'

/* add icons to the library */
library.add(faDiscord, faTiktok)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false;

Vue.prototype.$imagePath = process.env.VUE_APP_NAME_PATH;

Vue.use(Notifications);
Vue.use(VueCookies);
Vue.use(Vuelidate);
Vue.mixin(helpers);

new Vue({
	store,
	router,
	vuetify,
	render: h => h(App)
})
.$mount('#app');